body {
  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  img {
    width: 100%;
  }
} 

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

button {
  &:focus {
    outline: none !important;
    border: 0;
  }
}

section {
  width: 100%;
  min-height: 100vh;

  .section-title {
    font-size: 35px;
    padding: 30px 0;
  }
}

label {
  cursor: default;
}

.error-v {
  color: rgb(185, 74, 72);
}

.color-white {
  color: white;
}

.title-normal-font {
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.title-bold-font {
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 800;
}

.scale {
  transition: transform .2s;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1.2);
  }
}
