.footer {
  .footer-info a {
    color: white;
  }

  .social-icon {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;

    img {
      width: auto;
      height: auto;
    }
  }

  .footer-copyright {
    color: white;
    padding: 10px 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
  }
}