/*
*
* Install app section
*
*/

section {
  &.install-app {
    background: #2E3B46;
    position: relative;
  }
}

.element {
  .element__img-block {
    background-color: white;
    min-width: 74px;
    min-height: 74px;
    width: 74px;
    height: 74px;
    border-radius: 20px;
  }
}

.capabilities {
  .element {
    img {
      width: 48px;
    }

    p {
      margin: 0;
      color: #FFFFFF;
      font-size: 20px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      margin-left: 32px;
    }
  }
}

/*
*
* FAQ
*
*/

.accordion-section .accordion {
  .accordion-button,
  .accordion-collapse
  {
    border: 0;
  }

  .accordion-header {
    button {
      color: #0C1B25;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  } 
}

.accordion-body .accordion-body__text {
  color: #0C1B25;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
}


/*
*
* About project
*
*/

.about-project {
  background: #0C1B25;

  .about-project-content p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 3px;
  }

  h3 {
    color: white;
  }
}