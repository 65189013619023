.form-container {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 50px;

  .form-group {
    margin-bottom: 5px;
  }

  .btn-custom {
    margin-top: 30px;
  }
}

.form-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;

  select {
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    padding: 7px 20px;
    background-color: initial;
    width: 100%;
    white-space: pre-wrap;
  }

  label {
    font-size: 18px;
    color: #4a4a4a;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
  }

  input, select,
  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #212529;
    background-color: #fff;
    padding: 7px 20px;
    font-size: 18px;
    color: #4a4a4a;
    font-weight: 400;
    line-height: normal;
  }

  .select-icon {
    font-size: 26px;
    color: #4a4a4a;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: auto;
    right: 0;
    bottom: auto;
    left: auto;
    opacity: .4;
    z-index: 0;
  }

}

.btn-custom {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 9px 0;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: normal;
  background-color: #212529;
  -webkit-transition: background-color .3s ease-out;
  transition: background-color .3s ease-out;
  border: 0;
}

@media (min-width: 1200px) {
  .form-container {
    max-width: 350px;
  }

  .form-container .form-group {
    margin-bottom: 20px;
  }

  .form-container .form-group label {
    position: absolute;
    top: 10px;
    right: auto;
    bottom: auto;
    left: -10px;
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    margin-bottom: 0;
  }

  .form-container .form-group textarea {
    height: 100px;
  }
}
