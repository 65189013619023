.logo {
  img {
    width: 60px;
  }
}

.navbar {

  .navbar-nav {

    .nav-item {

      a {
        font-size: 18px;
        color: #FFFFFF;

        &.active:after {
          content: "";
          width: 70px;
          height: 3px;
          background-color: white;
          position: absolute;
          left: .5rem;
          bottom: 0;
        }
      }

      .nav-link {
        position: relative;
      }
    }
  }
}

.nav-same-padding {
  padding: 10px 24.5px;
}

.header-bottom {
  color: white;
  min-height: 595px;
  position: relative;
}

.payment-banner {
  padding: 70px 0;
  max-width: 420px;

  &-title {
    font-size: 35px;
    padding: 20px 0;
  }

  &-content {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    padding: 10px 0 20px 0;
  }
}

.market-logo a img {
  width: 160px;
}

.assets-block {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 896px;
  width: 100%;
}

.mobile-img-banner {
  position: absolute;
  right: 20px;
}

@media (max-width: 1360px) {
  .assets-block {
    max-width: 700px;
  }

  .header-bottom {
    min-height: 463px;
  }
}

@media (max-width: 1199px) {
  .assets-block {
    max-width: 550px;
  }

  .header-bottom {
    min-height: 100px;
  }
}

@media (max-width: 991px) {
  .header-bottom > .container {
    position: relative;
  }

  .assets-block {
    max-width: 450px;
  }

  .header-bottom {
    min-height: 719px;
  }

  .mobile-img-banner {
    width: 200px;
  }
}

@media (max-width: 433px) {
  .market-logo {
    flex-flow: column;
    align-items: center;
  }

  .mobile-img-banner {
    width: 200px;
    position: relative;
    right: 0;
  }
}