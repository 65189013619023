.contact-info .contact-info__row {
  font-size: 17px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: normal;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: stretch;
  justify-content: stretch;
  -webkit-box-align: center;
  align-items: center;

  a {
    color: #4a4a4a;
  }
}

.contact-info__row__link .iconify {
  color: #212529;
  font-size: 16px;
  margin-right: 5px;
  width: 16px;
  min-width: 16px;
}


.section-contacts {
  .map-container {
    width: 100%;
    height: 400px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .contact-info {
    width: 100%;
    max-width: 310px;
    margin: 0 auto 30px;
  }
}

@media (min-width: 992px) {
  .section-contacts .map-container {
    height: 100%;
  }
}
